import Appsignal from '@appsignal/javascript';
import { type JSSpan } from '@appsignal/types';

import { plugin as networkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as pathPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as windowPlugin } from '@appsignal/plugin-window-events';

import { ENV } from 'lib/react_on_rails/env';
import { determineAction } from './determine_action';
import { ignoreErrors } from './ignore_errors';
import QueryString from '../query_string';

export function initializeAppsignal({
  namespace, env,
}: {
  namespace: string;
  env?: typeof ENV;
}) {
  if (!env || !env.APPSIGNAL_ENABLED) return null;

  const appsignal = new Appsignal({
    key: env.APPSIGNAL_JS_API_KEY,
    namespace,
    revision: env.APP_COMMIT ?? undefined,
    ignoreErrors: env.APPSIGNAL_JS_IGNORE_ENABLED ? ignoreErrors : [],
  });

  appsignal.addDecorator((span: JSSpan) => {
    span.setAction(determineAction(window.location.pathname));

    if (window.location.search) {
      span.setParams(QueryString.parse(window.location.search));
    }

    return span;
  });

  appsignal.addDecorator((span: JSSpan) => {
    const tags: { team?: string; user?: string } = {};

    if (env.TELEMETRY_USER?.teamId) { tags.team = env.TELEMETRY_USER.teamId.toString(); }
    if (env.TELEMETRY_USER?.id) { tags.user = env.TELEMETRY_USER.id.toString(); }

    return span.setTags(tags);
  });

  return appsignal;
}

function initializeDefaultAppsignal() {
  if (!ENV) return;

  const appsignal = initializeAppsignal({
    namespace: 'frontend',
    env: ENV,
  });

  if (!appsignal) return;

  const plugins = [networkPlugin, pathPlugin];

  if (ENV.APPSIGNAL_JS_WINDOW_EVENTS) {
    plugins.push(windowPlugin);
  }

  // May want to consider `xhrEnabled: false` option for the networkPlugin
  // as this seems to mostly capture third party scripts vs. internal fetch
  plugins.forEach(plugin => appsignal.use(plugin()));

  return appsignal;
}

// eslint-disable-next-line import/no-default-export
export default initializeDefaultAppsignal();
