import { QueryString } from '../http';
import { toUnderscoreCase } from 'lib/formatters/strings';

export function transformOperatorKeysAndValues(obj) {
  const convertKey = (key) => {
    if (key.startsWith('populationAttribute')) {
      const attributeType = key.replace('populationAttribute', '').toLowerCase();
      return `population_attribute__${attributeType}`;
    }

    return toUnderscoreCase(key);
  };


  if (Array.isArray(obj)) {
    return obj.map(transformOperatorKeysAndValues);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const transformedKey = convertKey(key);
      const value = obj[key];

      acc[transformedKey] = transformOperatorKeysAndValues(value);

      return acc;
    }, {});
  }

  return obj;
}

export function encodedParticipantSearchFilter({ filterRules, projectFilters, operators }) {
  const searchFilter = {
    filter_rules: filterRules,
    project_filters: projectFilters,
    operators: transformOperatorKeysAndValues(operators),
  };

  const cleansedFilter = Object.keys(searchFilter).reduce((acc, key) => {
    const value = searchFilter[key];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});

  if (Object.values(cleansedFilter).length === 0) {
    // This will probably have to get tweaked once this method is more robust
    // but for now if there are no relevant pieces of data, return nothing
    return undefined;
  }

  return QueryString.base64Encode(cleansedFilter);
}
